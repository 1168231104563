"use strict";
(($, win, doc) => {    // JQ ES6 sw Pack 22.6
  
  const $win = $(win),
    $doc = $(doc),
    $html = $('html'),
    $body = $('body'),
    swns = {
      goToTop: function () {
        let $goToTop = $("#goto-top");
        $goToTop.hide();
        $.timeout = function (time) {
          return $.Deferred(function (dfd) {
            return setTimeout(dfd.resolve, time);
          }).promise();
        };
        $win.on("scroll", function () {
          if ($(this).scrollTop() > 789) {
            $goToTop.stop(true, true).fadeIn();
          } else {
            $goToTop.fadeOut();
          }
        });
        $goToTop.on("click", function (e) {
          e.preventDefault();
          $("body,html").animate({scrollTop: 0}, 200);
        });
      }
  }; // swns{}
  
  /* plugin Type BlockLink */
  $.fn.blocklinkAssy = function () { // bind under this
    $(this).on("click", function (e) {
      e.preventDefault();
      win.open($(this).find("a").attr("href"), $(this).find("a").attr("target") || "_self");
    }).css({cursor: "pointer"});
  };
  
  /*each popup(ChildList) sw 2022*/
  const eachPopUpList = function (popFrom_class) {
    let eachTimeout, target;
    eachTimeout = [];
    target = $(popFrom_class);
    target.each(function (i) {
      let $this;
      $this = $(this);
      $this.parent().hover((function () {
        let j;
        $this.parent().siblings().removeClass("show")
        target.hide();
        j = 0;
        while (j < eachTimeout.length) {
          clearTimeout(eachTimeout[j]);
          j++;
        }
        $this.parent().addClass("show")
        $this.show();
      }), (function () {
        eachTimeout[i] = setTimeout((function () {
          $this.parent().removeClass("show")
          $this.hide();
        }), 555);
      }));
    });
  };
  
  // add current class for nav
  const navCurrentAdd = ()=> {
    let filename = $(".current-control").attr("id");
    //let url = win.location.href.split("/");
    //url = url[url.length -1].split(".html")[0];
    $("#SideNavBase, .drawer-menu").find("> li").each(function(i, val) {
      $(this).removeClass("current");
      let nav_id = $(this).attr("id");
      nav_id = nav_id.replace(/nav-/g,'');
      if (nav_id === filename) { $(this).addClass("current"); }
    })
  };
  
  const checkMediaQuery = ()=> {
    // https://zenn.dev/yuki0410/articles/878f4afbff6668d4e28a-2
    // window.matchMedia をちゃんと使った方法
    const mediaQueryList = window.matchMedia("(max-width:767px)");
    /**
     * イベントリスナー
     */
    const listener = (event) => {
      if (event.matches) {
        console.log('768px未満');
      } else {
        console.log('768px以上');
      }
    };
    mediaQueryList.addEventListener("change", listener);
    listener(mediaQueryList);
  };
  
  const modalControl = ()=> {
    /**
     * add scroll stop
     * https://coliss.com/articles/build-websites/operation/javascript/prevent-page-scrolling-when-a-modal-is-open.html
     */
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
    $('.js-modal-open').each(function () {
      $(this).on('click', function () {
        let target = $(this).data('target');
        let modal = document.getElementById(target);
        $(modal).fadeIn(300);
        // モーダルが開いたら、bodyにfixedを付与 / set scroll position
        /*const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;*/
        $('body').addClass('modal_open')
        return false;
      });
    });
    $('.js-modal-close').on('click', function () {
      // モーダルが閉じられ時...
      const body = document.body;
      const scrollY = body.style.top;
      /*body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);*/
      $('.js-modal').fadeOut(300);
      $('body').removeClass('modal_open')
      return false;
    });
  };
  
  const animateElem = ()=> {
    /**
     * js_contAnimation
     */
    if (($(".contentsList").length)) {
      setTimeout(function () {
        $('.contentsList_item').each(function (i) {
          setTimeout(function () {
            $(".contentsList_item").eq(i).addClass("active");
          }, 99 * i);
        });
      }, 77);
    }
  };
  
  // do it DRF
  $(() => {
  
    
  
    // sw classic UI's
    swns.goToTop();
    $('.drawer').drawer();
    $(".hNavi").blocklinkAssy();
    // const's
    //checkMediaQuery();
    navCurrentAdd();
    modalControl();
  
    /* SideNav */
    // clone SideNavBase -> SideNavClone
    $("#SideNavBase > li").clone().appendTo("#SideNavClone ul");
    // add for WP menu-topmenu -> SideNavClone
    $("#menu-topmenu > li").clone().appendTo("#SideNavClone ul");
    // todo popupTarget || clickTarget
    //eachPopUpList("ul#SideNavBase > li.popupTarget > ul");
  
    /**
     * smooth scr
     * todo assy.jsかこっちかで。
     */
    $('a[href^="#"]').on("click",function(){
      const adjust = 0;
      const speed = 321;
      let href= $(this).attr("href");
      let target = $(href === "#" || href === "" ? 'html' : href);
      let position = target.offset().top + adjust;
      $('body,html').animate({scrollTop:position}, speed, 'easeOutCubic');
      return false;
    });
    
    
    
    /**
     * sw waypoint .active
     * "waypoint" + animate.css trigger
     * "waypoint animated" data-animate="fadeInLeft"
     * fixme .waypoint.contentsListは cssで .active 配下を加工すれば別個でいけるはず -> .contentsList はページに .waypoint が一回だけの場合(あんま使うと派手になりすぎ)
     */
    $(function () {
      $('.waypoint').waypoint(function (direction) {
        let isAnimate = $(this.element).data('animate') || "";
        let isPlay = $(this.element).data('playagain') || "";
        let activePoint = $(this.element);
        //console.info(activePoint[0], isAnimate);  // #------- sw Log --(´･_･`)
        if (direction === 'down') { //scroll down
          activePoint.addClass('active');
          animateElem();
          if (isAnimate) { activePoint.addClass(`${isAnimate} active`) }
        } else { //scroll up
          activePoint.removeClass('active');
          //animateElem();
          if (isPlay === 'playagain') { activePoint.removeClass(`${isAnimate} active`) }
        }
      }, {offset: '88%'}); // Topからの発火位置
  
      $('#bottom-in-view').waypoint(function (direction) {
        let isAnimate = $(this.element).data('animate') || "";
        let activePoint = $(this.element);
        if (direction === 'down') { //scroll down
          activePoint.addClass(`${isAnimate} active`);
        } else { //scroll up
          activePoint.removeClass(`${isAnimate} active`);
        }
      }, {offset: 'bottom-in-view'});
    });
    
   
    
  }); // DRF }
  
  /* .es6 サイドナビを固定する場合 (load)
  * fixme JS不具合があるのでこの際 stickey にしましょう 2207*/
  // sw sticky add class
  const select = document.querySelector('.headingWrapper'); // stickeyの上の要素を取得
  const observer = new window.IntersectionObserver((entry) => {
    if (!entry[0].isIntersecting) {
      document.querySelector('.list-fixed').classList.add('add-fixed');
    } else {
      document.querySelector('.list-fixed').classList.remove('add-fixed');
    }
  });
  observer.observe(select);

  
  

})(jQuery, window, document);

